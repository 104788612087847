import ReactComponent from "../app/ReactComponent"
import {Button, Card, Col, Layout, Result, Row} from "antd"
import React, {createRef} from "react"
import {Survey} from "../components/Survey"
import {Link} from "react-router-dom"
import {randomString} from "../helpers/utils"
import {PROPERTY_AP_SURVEY_KEY} from "../helpers/properties"
import {AP_SURVEY_EVENT_TYPE_ID} from "../helpers/misc"
import {SurveyAnnualControl} from "../components/SurveyAnnualControl"
import {SurveyRegistration} from "../components/SurveyRegistration"
import {SurveyGuide} from "../components/SurveyGuide"

export class QuestionnairePage extends ReactComponent {
  surveyFormRef = createRef()

  state = {
    complete: false,
    blocked_alert: false,
    constants: {
      AP_SURVEY_EVENT_TYPE_ID
    },
    gender: 1
  }

  async componentDidMount() {
    this.pushPageAnalytics("Dotazník - Moje Amber")

    const account = await this.$app.$store("account").getPersonalInfo()

    // block survey if invalid segment
    void this.updateState({
      blocked_alert: !(await this.$app.$store("account").isAllowedToShowSurvey()),
      gender: account.gender
    })
  }

  async submitForm() {

    void this.updateState({loading_page: true})

    try {
      const survey_data = this.surveyFormRef.current.getSurveyData()
      const external_id = randomString()

      await this.$api({
        action: "carecloud_api",
        method: "POST",
        route: "events",
        data: {
          event: {
            event_type_id: this.state.constants.AP_SURVEY_EVENT_TYPE_ID,
            customer_id: "{customer_id}",
            external_id
          },
          property_records: [
            {
              property_id: PROPERTY_AP_SURVEY_KEY,
              property_value: JSON.stringify(survey_data)
            }
          ]
        }
      })

      void this.updateState({complete: true})

    } catch (error) {

      console.log(error)

    } finally {

      this.scrollToTop()

      void this.updateState({loading_page: false})

    }
  }

  render() {
    return (
      <Layout tagName="main" className="male survey-page">
        <Row align="center">
          <Col md={24}>
            <h1>Dotazník</h1>
          </Col>

          {this.state.blocked_alert &&

            <Result
              title="Dotazník není k dispozici."
              extra={
                <Link to={"/"}>
                  <Button type="default" size="large">Domů</Button>
                </Link>
              }
            />

          }

          {this.state.complete &&

            <Card bordered={false} style={{maxWidth: 680}} className="card register">
              <Result
                status="success"
                title="Dotazník odeslán."
                subTitle="Děkujeme Vám za vyplnění dotazníku."
                extra={
                  <Link to="/">
                    <Button type="default" className={"btn btn-primary"} size="large">Domů</Button>
                  </Link>
                }/>
            </Card>

          }

          {!this.state.blocked_alert && !this.state.complete &&

            <>

              {this.props.type === "annual" ?
                <>

                  <Col sm={24} md={18} lg={16} xl={13}>
                    <p className="survey-text">Vyplněním všech otázek si významně zkrátíte čas, který byste strávili vyplněním otázek v odběrovém centru. Níže uvedené údaje nejsou povinné. Pokud si
                      nejste
                      u některé z otázek jistí odpovědí, otázku prosím nevyplňujte. Dotazník si můžete doplnit později ve Vašem portálu dárce. <span>Platnost vyplněného dotazníku je 48 hodin, nevyplňujte dříve než 48 hodin před Vašim odběrem!</span>
                    </p>
                  </Col>
                  <Col sm={24} md={18} lg={16} xl={12}>
                    <SurveyAnnualControl ref={this.surveyFormRef} initialValues={""} gender={this.state.gender}/>
                  </Col>
                  <Col sm={24} md={18} style={{marginTop: 50, marginBottom: 50}}>
                    <Button onClick={() => {
                      void this.submitForm()
                    }} className="btn btn-primary">Odeslat Formulář</Button>
                  </Col>
                </> : ""}

              {this.props.type === "registration" ?
                <>
                  <Col sm={24} md={18} lg={16} xl={13}>
                    <p className="survey-text">Vyplněním všech otázek si významně zkrátíte čas, který byste strávili vyplněním otázek v odběrovém centru. Níže uvedené údaje nejsou povinné. Pokud si
                      nejste
                      u některé z otázek jistí odpovědí, otázku prosím nevyplňujte. Dotazník si můžete doplnit později ve Vašem portálu dárce. <span>Platnost vyplněného dotazníku je 48 hodin, nevyplňujte dříve než 48 hodin před Vašim odběrem!</span>
                    </p>
                  </Col>
                  <Col sm={24} md={18} lg={16} xl={12}>
                    <SurveyRegistration ref={this.surveyFormRef} initialValues={""} gender={this.state.gender}/>
                  </Col>
                  <Col sm={24} md={18} style={{marginTop: 50, marginBottom: 50}}>
                    <Button onClick={() => {
                      void this.submitForm()
                    }} className="btn btn-primary">Odeslat Formulář</Button>
                  </Col>
                </> : ""}

              {this.props.type === "guide" ?
                <>
                  <Col sm={24} md={18} lg={16} xl={13}>
                    <p className="survey-text">Vyplněním všech otázek si významně zkrátíte čas, který byste strávili vyplněním otázek v odběrovém centru. Níže uvedené údaje nejsou povinné. Pokud si
                      nejste
                      u některé z otázek jistí odpovědí, otázku prosím nevyplňujte. Dotazník si můžete doplnit později ve Vašem portálu dárce. <span>Platnost vyplněného dotazníku je 48 hodin, nevyplňujte dříve než 48 hodin před Vašim odběrem!</span>
                    </p>
                  </Col>
                  <Col sm={24} md={18} lg={16} xl={12}>
                    <SurveyGuide ref={this.surveyFormRef} initialValues={""} gender={this.state.gender}/>
                  </Col>
                  <Col sm={24} md={18} style={{marginTop: 50, marginBottom: 50}}>
                    <Button onClick={() => {
                      void this.submitForm()
                    }} className="btn btn-primary">Odeslat Formulář</Button>
                  </Col>
                </> : ""}


            </>

          }

        </Row>
      </Layout>
    )
  }
}