import ReactComponent from "../app/ReactComponent";
import dayjs from "dayjs";
import {
  PROPERTY_AP_INSURANCE_KEY,
  PROPERTY_AP_JOB_KEY, PROPERTY_AP_NIN,
  PROPERTY_AP_OTHER_REFERENCE_KEY, PROPERTY_AP_PRACTITIONER_KEY,
  PROPERTY_AP_REFERENCE_KEY, PROPERTY_AP_SOCIAL_ID_KEY, PROPERTY_AP_SURVEY_KEY
} from "../helpers/properties";
import {
  AP_ADDITIONAL_ADDRESS_TYPE_ID,
  AP_AGREEMEMT_TYPE_ID,
  AP_CUSTOMER_SOURCE_ID, AP_REFERENCE_OTHER_OPTION_ID,
  AP_SURVEY_EVENT_TYPE_ID
} from "../helpers/misc";
import {getStoreNameBySystemId} from "../services/stores";
import {SSID_COOKIE_NAME, pushAnalytics} from "../helpers/global";
import Cookies from "js-cookie";
import {createSafePassword, fixZIP, getScript, isEmail, randomString} from "../helpers/utils";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Radio,
  Result,
  Layout,
  Row,
  Select,
  Space,
  Spin,
  Image,
  Switch, Tooltip,
  Typography,
} from "antd";
import React, {createRef} from "react";
import {CaretDownOutlined, InfoCircleOutlined, DownOutlined, UpOutlined} from '@ant-design/icons'
import {GDPRModal} from "../components/form/GDPRModal";
import {Link} from "react-router-dom";
import {Survey} from "../components/Survey";
import Empty from 'antd/es/empty/empty'
import {ProfilingModal} from "../components/form/ProfilingModal";
import {MarketingCommunicationModal} from "../components/form/MarketingCommunicationModal";
import {SurveyRegistration} from '../components/SurveyRegistration'

const layout = {
  labelCol: {
    span: 7,
  },
  wrapperCol: {
    span: 17,
  },
};

const emptyData = <div className="empty-data"><Empty /><div>Žádná data nejsou k dispozici</div></div>

export class RegistrationPage extends ReactComponent {
  registrationFormRef = createRef()
  surveyFormRef = createRef()

  state = {
    constants: {
      AP_AGREEMEMT_TYPE_ID,
      AP_SURVEY_EVENT_TYPE_ID,
      AP_ADDITIONAL_ADDRESS_TYPE_ID,
      AP_CUSTOMER_SOURCE_ID,
      AP_REFERENCE_OTHER_OPTION_ID
    },
    loading_page: false,
    first_submit: false,
    complete: false,
    additional_info: false,
    contact_address: false,
    reference_detail: false,
    survey: false,
    gender: 1,
    max_date: dayjs().subtract(18, 'year'),
    agreement_gtc: true,
    agreement_label: 'Souhlas se zpracováním osobních údajů',
    agreement_text: false,
    references: [],
    insurance_companies: [],
    jobs: [],
    autocomplete_placeholder: 'Zadejte část adresy nebo název firmy',
    autocomplete_address: {
      street: '',
      descnumber: '',
      orientnumber: '',
      city: '',
      zip: ''
    },
    autocomplete_contact_address: {
      street: '',
      descnumber: '',
      orientnumber: '',
      city: '',
      zip: ''
    },
    is_logged: false,
    created_id: false,
    password: false,
    reference: false,
    insurance: false,
    job: false,
    social_id: false,
    practitioner: false,
    national_id: false,
    contact_address_id: false,
    other_reference: '',
    branch: '',
  }

  async componentDidMount() {

    this.pushPageAnalytics("Registrace do Moje Amber")

    void this.getDataForRegistration()
  }

  async getDataForRegistration() {

    void this.updateState({loading_page: true})

    try {

      // get places api
      if (!window.hasOwnProperty('google')) await getScript(`https://maps.googleapis.com/maps/api/js?key=AIzaSyABwFr3wK7iKKzNEWYMtatQd5G0VqgRAWE&libraries=places`)

      const {items: references = []} = await this.$api({
        action: 'carecloud_api',
        route: `customer-properties/${PROPERTY_AP_REFERENCE_KEY}`
      })

      const {items: insurances = []} = await this.$api({
        action: 'carecloud_api',
        route: `customer-properties/${PROPERTY_AP_INSURANCE_KEY}`
      })

      const {items: jobs = []} = await this.$api({
        action: 'carecloud_api',
        route: `customer-properties/${PROPERTY_AP_JOB_KEY}`
      })

      const {name: agreement_label = false, text: agreement_text = false} = await this.$api({
        action: 'carecloud_api',
        route: `agreements/${this.state.constants.AP_AGREEMEMT_TYPE_ID}`
      })

      // get global stores
      const stores = await this.$app.$store('common').getStores()

      // set branch for analytics
      await this.setBranch()

      await this.updateState({
        references: references,
        insurance_companies: insurances,
        jobs: jobs,
        agreement_label: (agreement_label ?? this.agreement_label),
        agreement_text: (agreement_text ?? this.agreement_text),
        stores: stores
      })

      return Promise.resolve()

    } catch (error) {

      this.handleError(error)
      console.log(error)

    } finally {

      void this.updateState({
        loading_page: false
      })

    }

  }

  async handleRegistrationFormSubmit(data) {
    void this.updateState({
      first_submit: true,
      loading_page: true
    })

    try {
      const agreement_gtc = 1//(+data.agreement_gtc)
      const agreement_profiling = +(data.agreement_profiling || 2)
      const agreement_marketing_communication = +!(data.agreement_marketing_communication || false)


      if (!data.first_name || !data.last_name || !data.birthdate || !data.email || !data.phone || !data.reference) throw 'Položky označené * jsou povinné.'
      if (this.reference_detail && !data.other_reference) throw 'Položky označené * jsou povinné.'
      if (!isEmail(data.email)) throw 'Neplatný e-mail.'
      //if (!agreement_gtc) throw 'Souhlas se zpracováním osobních údajů je povinný.'

      if (!this.state.created_id) {

        const password = createSafePassword()
        const birthdate = dayjs(data.birthdate).format("YYYY-MM-DD")
        const phone = (data.phone_prefix + data.phone).replace(/\s/g, '')

        // create account
        const {customer_id} = await this.$api({
          action: 'carecloud_api',
          method: 'POST',
          route: 'customers',
          data: {
            customer: {
              personal_information: {
                pre_nominals: data.pre_nominals,
                first_name: data.first_name,
                last_name: data.last_name,
                gender: data.gender,
                birthdate: birthdate,
                email: data.email,
                phone: phone,
                language_id: 'cs',
                address: {
                  address1: data.street,
                  address2: data.descnumber,
                  address3: data.orientnumber,
                  city: data.city,
                  zip: fixZIP(data.zip),
                  country_code: 'cz'
                },
                agreement: {
                  agreement_gtc: agreement_gtc,
                  agreement_profiling: agreement_profiling,
                  agreement_marketing_communication: agreement_marketing_communication
                }
              }
            },
            password,
            autologin: false,
            customer_source: {
              customer_source_id: this.state.constants.AP_CUSTOMER_SOURCE_ID
            },
            property_records: this.setPropertyRecords(data)
          }
        })

        await this.updateState({
          created_id: customer_id,
          password: password
        })

      }

      if (this.state.created_id && this.state.password) {

        // login
        await this.$api({
          action: 'carecloud_api',
          route: 'temporary-login',
          method: 'POST',
          data: {
            login_type: 'email',
            login_value: data.email,
            password: this.state.password
          }
        })

        await this.updateState({is_logged: true})

        // add reference
        if (data.reference && !this.state.reference) {

          await this.setEnumProperty(PROPERTY_AP_REFERENCE_KEY, data.reference)
          await this.updateState({reference: data.reference})

        }

        // reference detail
        if (this.state.reference_detail && !this.state.other_reference) {

          await this.setProperty(PROPERTY_AP_OTHER_REFERENCE_KEY, data.other_reference)
          await this.updateState({other_reference: data.other_reference})

        }

        if (this.state.additional_info) {

          // add insurance
          if (data.insurance && !this.state.insurance) {

            await this.setEnumProperty(PROPERTY_AP_INSURANCE_KEY, data.insurance)
            await this.updateState({insurance: data.insurance})

          }

          // add job
          if (data.job && !this.state.job) {

            await this.setEnumProperty(PROPERTY_AP_JOB_KEY, data.job)
            await this.updateState({job: data.job})

          }

          // add practitioner
          if (data.practitioner && !this.state.practitioner) {

            await this.setProperty(PROPERTY_AP_PRACTITIONER_KEY, data.practitioner)
            await this.updateState({practitioner: data.practitioner})

          }

          // add national identification number
          if (data.national_id && !this.state.national_id) {

            await this.setProperty(PROPERTY_AP_NIN, data.national_id)
            await this.updateState({national_id: data.national_id})

          }

          // add contact address
          if (data.contact_street && !this.state.contact_address_id) {

            const {additional_address_id} = await this.$api({
              action: 'carecloud_api',
              route: `customers/${this.state.created_id}/addresses`,
              method: 'POST',
              data: {
                additional_address: {
                  address_type: this.state.constants.AP_ADDITIONAL_ADDRESS_TYPE_ID,
                  address: {
                    address1: data.contact_street,
                    address2: data.contact_descnumber,
                    address3: data.contact_orientnumber,
                    city: data.contact_city,
                    zip: fixZIP(data.contact_zip),
                    country_code: 'cz'
                  }
                }
              }
            })

            await this.updateState({contact_address_id: additional_address_id})

          }

        }

        // survey
        if (this.state.survey) {
          
          const survey_data = this.surveyFormRef.current.getSurveyData()
          const external_id = randomString()

          await this.$api({
            action: 'carecloud_api',
            method: 'POST',
            route: 'events',
            data: {
              event: {
                event_type_id: this.state.constants.AP_SURVEY_EVENT_TYPE_ID,
                customer_id: this.state.created_id,
                external_id
              },
              property_records: [
                {
                  property_id: PROPERTY_AP_SURVEY_KEY,
                  property_value: JSON.stringify(survey_data)
                }
              ]
            }
          })

        }

      }

      pushAnalytics({
        event: 'registration',
        registration: {
          type: 'implicit',
          method: 'credentials',
          name: 'Registrace - uživatele',
          city: data.city,
          //from_branch: this.branch,
          text: 'Registrovat'
        },
        user: {
          id: this.state.created_id,
          type: 'Customer',
          gender: data.gender
        }
      })

      void this.updateState({
        loading_page: false,
        complete: true
      })

      // TODO: Reset form
      //this.$refs.form.reset()

    } catch (error) {

      this.handleError(error)
      console.log(error)

    } finally {

      if (this.state.is_logged) {

        // logout
        await this.$api({action: 'logout'})

        void this.updateState({is_logged: false})

      }

      void this.updateState({loading_page: false})

    }

  }

  setPropertyRecords(data) {
    let properties = []

    if (data.social_id)
      properties.push({
        property_id: PROPERTY_AP_SOCIAL_ID_KEY,
        property_value: data.social_id
      })

    return properties
  }

  async setProperty(property_id, property_value) {

    return this.$api({
      action: 'carecloud_api',
      method: 'POST',
      route: `customers/${this.state.created_id}/property-records`,
      data: {
        property_record: {
          property_id: property_id,
          property_value: property_value
        }
      }
    })

  }

  async setEnumProperty(property_id, property_value) {

    return this.$api({
      action: 'carecloud_api',
      method: 'POST',
      route: `customers/${this.state.created_id}/property-records`,
      data: {
        property_record: {
          property_id: property_id,
          property_value: [
            {
              id: property_value
            }
          ]
        }
      }
    })

  }

  async setBranch() {
    const store_system_id = Cookies.get(SSID_COOKIE_NAME)

    if (store_system_id) {

      const store_name = getStoreNameBySystemId(this.state.stores, store_system_id, false)

      if (store_name)
        await this.updateState({
          branch: store_name
        })

    }

  }

  onReferenceChange(data) {
    void this.updateState(
      {reference_detail: (this.state.constants.AP_REFERENCE_OTHER_OPTION_ID === data)}
    )
  }

  onAgreementGTCChange(data) {
    void this.updateState({agreement_gtc: data})

  }

  onGenderChange = (e) => {
    void this.updateState({
      gender: e.target.value
    })
  }

  render() {
    return (
      <Layout tagName="main">
        <Row align="center" justify="center" className="margin-auto" gutter={[{xs: 0, md: 0, lg: 0, xl: 100}, 50]}>
          <Col xs={24} md={24} lg={24} xl={8} className="col-image-moje-amber-pencil">
            <Image preview={false} src={require("../image/moje-amber-pencil@2x.png")}></Image>
          </Col>
          <Col xs={23} md={18} lg={16} xl={16} className="col-image-moje-amber-pencil">
            <Spin spinning={this.state.loading_page && !this.state.complete} size={"large"}>
              <Card bordered={false} style={{maxWidth: 680}} className="card register">
                {this.state.complete && <Result
                  status="success"
                  title="Registrace byla úspěšná."
                  subTitle="Odkaz pro nastavení hesla Vám přijde na e-mail."
                  extra={
                    <Link to="/">
                      <Button type="default" size="large">Přihlášení</Button>
                    </Link>
                  }/>
                }

                {!this.state.complete &&
                  <>
                    <h1>Registrace dárce</h1>
                    <p>Už máte účet Moje Amber? <Link to="/">Přihlaste se</Link></p>

                    <Form
                      {...layout}
                      ref={this.registrationFormRef}
                      layout="horizontal"
                      onFinish={(...args) => this.handleRegistrationFormSubmit(...args)}
                      onFinishFailed={() => {this.updateState({first_submit: true})}}
                      scrollToFirstError={true}>
                      <div className="form-container">
                        <Form.Item
                          label="Titul"
                          name="pre_nominals"
                        >
                          <Input placeholder="Zadejte titul" size="large"/>
                        </Form.Item>
                        <Form.Item
                          label="Jméno"
                          name="first_name"
                          className="required"
                          rules={[{
                            required: true,
                            message: "Prosím vyplňte jméno"
                          }]}>
                          <Input placeholder="Zadejte jméno" size="large"/>
                        </Form.Item>

                        <Form.Item
                          label="Přijmení"
                          name="last_name"
                          rules={[{
                            required: true,
                            message: "Prosím vyplňte Přijmení"
                          }]}>
                          <Input placeholder="Zadejte přijmení" size="large"/>
                        </Form.Item>

                        <Form.Item
                          label="Pohlaví"
                          name="gender"
                          className="flex-start"
                          rules={[{
                            required: true,
                            message: "Prosím vyplňte Přijmení"
                          }]}
                          initialValue={1}>
                          <Radio.Group onChange={this.onGenderChange}>
                            <Radio value={1}>Muž</Radio>
                            <Radio value={2}>Žena</Radio>
                          </Radio.Group>
                        </Form.Item>

                        <Form.Item
                          label="E-mail"
                          name="email"
                          rules={[{
                            required: true,
                            message: "Prosím vyplňte E-mail"
                          }]}>
                          <Input placeholder="Zadejte váš email" size="large"/>
                        </Form.Item>

                        <Form.Item
                          label="Telefonní číslo"
                          name="phone"
                          rules={[{
                            required: true,
                            message: "Prosím vyplňte Telefonní číslo"
                          }]}>

                          <Input placeholder="Zadejte vaše tel. číslo"
                                 addonBefore={<Form.Item name="phone_prefix" initialValue="420" noStyle>
                                   <Select
                                     suffixIcon={<CaretDownOutlined className="ant-select-suffix"/>}
                                     style={{
                                       width: 85
                                     }}
                                   >
                                     <Select.Option value="420">+420</Select.Option>
                                     <Select.Option value="421">+421</Select.Option>
                                     <Select.Option value="380">+380</Select.Option>
                                   </Select>
                                 </Form.Item>}
                                 style={{
                                   width: '100%',
                                 }}
                          />
                        </Form.Item>

                        <Form.Item
                          label="Datum narození"
                          name="birthdate"
                          className="datepicker"
                          initialValue={dayjs(this.state.max_date)}
                          rules={[{
                            required: true,
                            message: "Prosím vložte datum narození"
                          }]}>
                          <DatePicker
                            placeholder="    .    ."
                            format="DD.MM.YYYY"
                            inputReadOnly={("ontouchstart" in document.documentElement)}
                            disabledDate={current => (current > dayjs(this.state.max_date))}
                            size="large"/>
                        </Form.Item>

                        <Form.Item
                          label="Odkud o nás víte?"
                          name="reference"
                          rules={[{
                            required: true,
                            message: "Prosím vyplňte položku"
                          }]}>
                          <Select
                            placeholder="Prosím vyberte"
                            suffixIcon={<CaretDownOutlined className="ant-select-suffix"/>}
                            size="large"
                            notFoundContent={emptyData}
                            onChange={(...args) => this.onReferenceChange(...args)}
                            options={this.state.references.map(item => ({
                              label: item.name,
                              value: item.id
                            }))}/>
                        </Form.Item>

                        {this.state.reference_detail && <Form.Item label="Jiné" name="other_reference">
                          <Input placeholder="Doplňte, odkud o nás víte" size="large"/>
                        </Form.Item>
                        }
                      </div>


                      <Divider orientation="center" plain>
                        <Button type="primary" className="btn-collapsible" onClick={() => {
                          void this.updateState({additional_info: !this.state.additional_info})
                        }}>Přidat doplňující údaje {this.state.additional_info ? <UpOutlined/> :
                          <DownOutlined/>}</Button>
                      </Divider>

                      {!this.state.additional_info &&
                        <div className="form-container"><p className="speech helper-text">Vyplnění všech doplňujících
                          údajů významně zkrátí čas, který
                          byste
                          strávili v
                          odběrovém centru při vstupní prohlídce. Níže uvedené údaje nejsou povinné. Vámi poskytnuté
                          údaje
                          jsou potřebné pro Vaši evidenci v našem zdravotnickém zařízení a jsou používány pro potřeby
                          společnosti Amber Plasma a.s.</p></div>
                      }

                      {this.state.additional_info &&

                        <div className="additional-info">
                          <Form.Item label="Pojišťovna" name="insurance" className="flex-start">
                            <Select
                              placeholder="Prosím vyberte"
                              suffixIcon={<CaretDownOutlined className="ant-select-suffix"/>}
                              size="large"
                              notFoundContent={emptyData}
                              onChange={(...args) => this.onReferenceChange(...args)}
                              style={{width: 150}}
                              options={this.state.insurance_companies.map(item => ({
                                label: item.name,
                                value: item.id
                              }))}/>
                          </Form.Item>

                          <Form.Item label="Rodné číslo" name="social_id">
                            <Input placeholder="" size="large"/>
                          </Form.Item>

                          <Form.Item label="Ulice" name="street" className="GoogleAutocompleteAddressTrigger">
                            <Input placeholder="Zadejte část adresy" size="large"/>
                          </Form.Item>

                          <Form.Item label="Číslo popisné / orientační" className="contact-descnumber flex-start">
                            <Form.Item name="descnumber" style={{display: 'inline-block', width: 100}}>
                              <Input placeholder="" size="large"/>
                            </Form.Item>
                            <span style={{margin: 'auto 5px'}}>/</span>
                            <Form.Item name="orientnumber" style={{display: 'inline-block', width: 100}}>
                              <Input placeholder="" size="large"/>
                            </Form.Item>
                          </Form.Item>

                          <Form.Item label="Město" name="city">
                            <Input placeholder="" size="large"/>
                          </Form.Item>

                          <Form.Item label="PSČ" name="zip" className="flex-start">
                            <Input placeholder="" size="large" style={{width: 100}}/>
                          </Form.Item>

                          <Form.Item label="Praktický lékař" name="practitioner">
                            <Input placeholder="" size="large"/>
                          </Form.Item>

                          <Form.Item label="Povolání" name="job">
                            <Select
                              placeholder="Prosím vyberte"
                              suffixIcon={<CaretDownOutlined className="ant-select-suffix"/>}
                              size="large"
                              notFoundContent={emptyData}
                              options={this.state.jobs.map(item => ({
                                label: item.name.split(" - ").slice(0, 2).join(", "),
                                value: item.id
                              }))}/>
                          </Form.Item>

                          <Form.Item label="Číslo pojištěnce" name="national_id">
                            <Input placeholder="" size="large" suffix={
                              <Tooltip
                                title="Číslo pojištěnce vyplňte pouze pokud nemáte Rodné číslo nebo pokud je Vaše Číslo pojištěnce jiné než Rodné číslo.">
                                <InfoCircleOutlined style={{color: 'rgba(0,0,0,.45)'}}/>
                              </Tooltip>
                            }/>
                          </Form.Item>


                          <p className="speech helper-text">Vyplnění všech doplňujících údajů významně zkrátí čas, který
                            byste strávili v
                            odběrovém centru při vstupní prohlídce. Níže uvedené údaje nejsou povinné. Vámi poskytnuté
                            údaje
                            jsou potřebné pro Vaši evidenci v našem zdravotnickém zařízení a jsou používány pro potřeby
                            společnosti Amber Plasma a.s.</p>

                        </div>
                      }


                      <Divider orientation="center" plain>
                        <Button type="primary" className="btn-collapsible" onClick={() => {
                          void this.updateState({contact_address: !this.state.contact_address})
                        }}>Kontaktní adresa {!this.state.contact_address ? <DownOutlined/> : <UpOutlined/>}</Button>
                      </Divider>

                      {!this.state.contact_address &&
                        <div className="form-container"><p className="speech helper-text">Vyplňte vaší kontaktní adresu,
                          pokud se liší od standardní
                          adresy.</p></div>
                      }

                      {this.state.contact_address && <div className="contact-address">
                        <Form.Item label="Ulice" name="contact_street" className="GoogleAutocompleteAddressTrigger">
                          <Input placeholder="Zadejte část adresy" size="large"/>
                        </Form.Item>

                        <Form.Item label="Číslo popisné / orientační" className="contact-descnumber flex-start">
                          <Form.Item name="contact_descnumber"
                                     style={{display: 'inline-block', width: 100}}>
                            <Input placeholder="" size="large"/>
                          </Form.Item>
                          <span style={{margin: 'auto 5px'}}>/</span>
                          <Form.Item name="contact_orientnumber"
                                     style={{display: 'inline-block', width: 100}}>
                            <Input placeholder="" size="large"/>
                          </Form.Item>
                        </Form.Item>

                        <Form.Item label="Město" name="contact_city">
                          <Input placeholder="" size="large"/>
                        </Form.Item>

                        <Form.Item label="PSČ" name="contact_zip" className="flex-start">
                          <Input placeholder="" size="large" style={{width: 100}}/>
                        </Form.Item>
                      </div>}

                      <Divider orientation="center" plain>
                        <Button type="primary" className="btn-collapsible" onClick={() => {
                          void this.updateState({survey: !this.state.survey})
                        }}>Dotazník {!this.state.survey ? <DownOutlined/> : <UpOutlined/>}</Button>
                      </Divider>

                      <div className="form-container">
                        <p className="speech helper-text">Vyplněním všech otázek si významně zkrátíte čas, který byste strávili vyplněním otázek v odběrovém centru. Níže uvedené údaje nejsou povinné. Pokud si nejste u některé z otázek jistí odpovědí, otázku prosím nevyplňujte. Dotazník si můžete doplnit později ve Vašem portálu dárce. Podrobné poučení pro dárce naleznete <a href="https://www.amberplasma.com/file/686" target="_blank" rel="noreferrer" style={{color: "#004B6C", fontWeight: 400}}>zde</a>.<strong> Platnost vyplněného dotazníku je 48 hodin, nevyplňujte dříve než 48 hodin před Vašim odběrem!</strong>
                        </p>
                      </div>

                      {this.state.survey &&

                        <div className="survey form-container" style={{marginTop: "1.5em"}}>
                          <SurveyRegistration ref={this.surveyFormRef} gender={this.state.gender.toString()} />
                        </div>

                      }

                      <Form.Item className="consent mb-0">
                        <Form.Item
                          name="agreement_gtc"
                          className="switch-container"
                        >
                          <Switch id="agreement_gtc"
                            style={{marginRight: '0.9em'}}/>
                        </Form.Item>
                        <label htmlFor="agreement_gtc">Souhlasím se zpracováním svých osobních údajů za účelem vedení evidence opakovaných dárců a jejich odběrů, odměn a bonusů a související komunikace s dárci <GDPRModal/>
                        </label>
                      </Form.Item>

                      <Form.Item className="consent">
                        <Form.Item
                          name="agreement_profiling"
                          className="switch-container">
                          <Switch id="agreement_profiling" style={{marginRight: '0.9em'}}/>
                        </Form.Item>
                        <label htmlFor="agreement_profiling" className="required">Souhlasím se zpracováním svých osobních údajů za účelem profilování <ProfilingModal/>
                        </label>
                      </Form.Item>

                      <Form.Item className="consent">
                        <Form.Item
                          name="agreement_marketing_communication"
                          className="switch-container">
                          <Switch id="agreement_marketing_communication" style={{marginRight: '0.9em'}}/>
                        </Form.Item>
                        <label htmlFor="agreement_marketing_communication" className="required">Nesouhlasím s marketingovou komunikací (zasíláním obchodních sdělení) <MarketingCommunicationModal/>
                        </label>
                      </Form.Item>

                      <Row align="center">
                        <Col xs={24} md={20}>
                          <Typography.Paragraph type="secondary" style={{margin: '1em 1em 0'}} className="text-info">Vyplněním výše uvedeného formuláře a kliknutím na pole "Registrovat" potvrzuji, že jsem se seznámil/a s informacemi o zpracování osobních údajů, které jsou dostupné <a href="https://www.amberplasma.com/file/617" rel="noreferrer" target="_blank">zde</a>.
                          </Typography.Paragraph>
                        </Col>
                      </Row>
                      <Space style={{marginTop: '30px'}} size={[18, 10]} className="btn-container">
                        <Button htmlType="submit" type="primary" className="btn btn-primary">Registrovat</Button>
                      </Space>
                      <p><Typography.Text type="secondary" className="text-info">Údaje označené (<span
                        className="red">*</span>) jsou povinné.</Typography.Text></p>
                    </Form>
                  </>
                }
              </Card>
            </Spin>
          </Col>
        </Row>
      </Layout>
    )
  }
}